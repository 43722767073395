.layout-grid-12 {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 1rem;
}

.layout-page {
    padding: 0 2rem;
    max-width: 1400px;
}

.layout-padding {
    padding: 4rem 2rem 5rem;
}

@media (max-width: 770px) {
    .layout-padding {
        padding: 2rem 0 4rem;
    }
}