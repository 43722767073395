@import "../../styles/colors.scss";

.LoginButton {
    &__logout {
        color: $color__white;
        cursor: pointer;
        z-index: 10;
        padding: 0.5rem;

        &:hover {
            color: $color__gray-3;
        }
    }
}
