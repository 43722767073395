.Grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    align-items: start;
    height: 100%;

    &--center,
    &--start,
    &--end {
        @extend .Grid;
    }

    &--center {
        align-items: center;
    }

    &--start {
        align-items: start;
    }
    &--end {
        align-items: end;
    }
}
