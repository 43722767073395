@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.Steps {
    $height: 2rem;
    $width: 2rem;
    $border: 2px;

    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    gap: 2rem;

    &__line {
        display: flex;
        justify-content: center;
        flex: 1;
        width: 100%;
        max-width: 10rem;
        margin-top: 1rem;

        span {
            width: calc(100% - 4rem);
            max-width: 30rem;
            height: 2px;
            background-color: $color__gray-2;
            border-radius: 4px;
        }

        &:last-child {
            display: none;
        }
    }

    &__step {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        &:before, &:after {
            position: absolute;
            content: "";
            height: 2px;
            width: calc(50% - $width);
            background-color: $color__gray-2;
            left: -1rem;
            top: calc($height / 2 + $border);
        }

        &:after {
            left: unset;
            right: -1rem;
        }

        &:first-child:before, &:last-child:after {
            display: none;
        }

        p {
            text-align: center;
            font-weight: $fw-medium;
        }
    }

    &__circle {
        display: flex;
        justify-content: center;
        position: relative;
        width: calc($width + 2 * $border);
        height: calc($height + 2 * $border);
        border: $border solid transparent;
        box-sizing: content-box;
        border-radius: 100%;

        &--uncomplete {
            @extend .Steps__circle;
            border-color: $color__gray-2;
        }

        &--active {
            @extend .Steps__circle;
            position: relative;
            border-color: $color__green;
            display: flex;
            align-items: center;

            .Steps__circle--inner {
                width: calc($width * 0.9);
                height: calc($height * 0.9);
                background-color: $color__green;
                border-radius: 100%;
            }
        }

        &--complete,
        &--number {
            @extend .Steps__circle;
            border-color: $color__green;
            background-color: $color__green;

            &:hover {
                cursor: pointer;

                p {
                    color: $color__gray-2;
                }
            }
        }
    }

    &__icon {
        position: absolute;
        display: flex;
        justify-self: center;
        justify-content: center;
        align-items: center;
        height: $height;
        width: $width;
        border: $border solid transparent;
        box-sizing: content-box;
        color: $color__white;
        font-weight: $fw-bold;

        svg {
            width: 100%;
            height: 1rem;
        }
    }
}
