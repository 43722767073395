@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.Title {
    text-transform: uppercase;
    font-size: $fs-l;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    @each $name, $color in $colors {
        &--#{$name} {
            @extend .Title;
            position: relative;
            text-align: left;
            margin: 0rem;
            padding-left: 1.5rem;

            &:before {
                content: "";
                position: absolute;
                height: 16px;
                width: 16px;
                display: inline-block;
                border-radius: 100%;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                background-color: $color;
            }
        }
    }

    @each $name, $fontsize in $fontsizes {
        $i: index($fontsizes, ($name $fontsize));
        &--#{$name} {
            @extend .Title;
            font-size: $fontsize;

            &:before {
                @if $i < length($fontsizes) {
                    $i: $i + 1;
                }
                $size: nth($fontsizes, $i);
                $size: nth($size, 2);
                height: $size;
                width: $size;
            }
        }
    }
}
