@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.GoalSetting {
    &__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: $fw-bold;
        height: 100%;
    }

    &__headline {
        margin-bottom: 0.5rem;
        font-size: $fs-s;
    }

    &__center {
        display: flex;
        align-items: center;
        height: 100%;
    }

    &__line {
        height: 1px;
        width: 100%;
        background-color: $color__gray-3;
        display: block;
    }

    &__relative {
        position: relative;
    }

    &__absolute {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__section {
        padding: 0.5rem 0;
    }

    &__margin {
        margin: 1rem 0;
    }
}
