@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";

.ButtonAction {
    display: inline-block;

    svg {
        height: 20px;
        width: 20px;
    }

    &:hover {
        svg {
            fill: $color__gray-2;
        }
    }

    button:has(svg):disabled {
        background-color: transparent;
        border-color: transparent;
        opacity: 0.3;
    }

    &__inner {
        height: 2rem;
        display: flex;
        align-items: center;
        gap: 0.2rem;
        font-weight: $fw-bold;
        padding: 0.3rem 0.5rem;
        justify-content: center;
    }

    &__cross {
        position: relative;
        width: 17px;
        height: 17px;

        &:before,
        &:after {
            position: absolute;
            content: "";
            height: 17px;
            width: 2px;
            background-color: $color__black;
            top: 0;
            left: 50%;
        }

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }

        &:hover {
            &:before,
            &:after {
                background-color: $color__gray-2;
            }
        }
    }
}
