.LoginPage {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;

    &__title {
        margin-top: 0;
    }

    &__button {
        width: 300px;
        height: 50px;
    }
}