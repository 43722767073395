@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.Dropdown {
    position: relative;
    cursor: pointer;
    height: 100%;
    width: 100%;
    max-width: 14rem;

    &--fullWidth {
        @extend .Dropdown;
        width: 100%;
    }

    &--active {
        .Dropdown__selected:after {
            transform-origin: center;
            transform: rotate(-135deg) translate(3px, calc(-50% + 3px));
            -webkit-transform: rotate(-135deg) translate(0, calc(-50% + 12px));
        }
    }

    &__selected {
        display: flex;
        align-items: center;
        border-radius: 30px;
        background-color: darken($color__gray-1, 5);
        padding: 0.2rem 2.5rem 0.2rem 1.2rem;
        position: relative;
        height: 100%;

        &:after {
            content: "";
            position: absolute;
            right: 1.2rem;
            top: calc(50%);
            border: solid $color__white;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 4px;
            transform: rotate(45deg) translate(-3px, calc(-50% - 3px));
            -webkit-transform: rotate(45deg) translate(-3px, calc(-50% - 3px));
        }

        &--placeholder {
            opacity: 0.55;
        }

        &--tag {
            cursor: default;
            position: relative;
            background-color: $color__green;
            padding: 0.3rem 2rem 0.3rem 0.7rem;
            border-radius: 20px;
            color: $color__white !important;
            margin-right: 0.3rem;

            &:last-child {
                margin-right: 0;
            }
        }

        &--error {
            border-color: $color__red !important;
        }
    }

    &__select {
        position: absolute;
        margin: 0;
        padding: 0;
        min-width: 100%;
        border-radius: 0 0 4px 4px;
        overflow: hidden;
        overflow-y: auto;
        max-height: 11rem;

        &--close {
            visibility: hidden;
            opacity: 0;
        }

        &--open {
            z-index: 100;
            visibility: visible;
            opacity: 1;
        }

        &--top {
            bottom: 100%;
            border-radius: 4px 4px 0 0;
        }
    }

    &__option {
        clear: both;
        display: flex;
        align-items: center;
        padding: 0 0.5rem;
        width: 100%;
        background-color: $color__gray-3;

        &Wrapper {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            border-bottom: 1px solid $color__gray-2;
            padding: 0.7rem 0;
            width: 100%;
        }

        &:last-child > div {
            border: none;
        }

        &:first-child {
            padding-top: 0.5rem;
        }
        &:last-child {
            padding-bottom: 0.5rem;
        }

        &:hover {
            background-color: $color__gray-2;
        }

        &--addition {
            @extend .Dropdown__option;
            border-top: 1px solid rgba($color__black, 0.2);
        }
    }

    @each $name, $color in $colors {
        &--#{$name} {
            @extend .Dropdown;
            $parent: ".Dropdown";
            #{$parent}__selected {
                background-color: $color;
                border-color: darken($color, 15);
                color: invert($color);

                &--selected {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &:after {
                    border-color: $color__green;
                }
            }

            #{$parent}__option {
                background-color: darken($color, 5);
                
                &Wrapper {
                    border-color: $color__gray-3;
                }

                &:hover {
                    background-color: darken($color, 10);
                }
            }
        }
    }

    @each $name, $fontsize in $fontsizes {
        &--#{$name} {
            font-size: $fontsize;
        }
    }
}
