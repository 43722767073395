@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

$ff: 'IBM Plex Sans', sans-serif;

$fs-s: calc(1rem / 16 * 14);
$fs-m: calc(1rem / 16 * 16);
$fs-l: calc(1rem / 16 * 20);
$fs-xl: calc(1rem / 16 * 28);
$fs-xxl: calc(1rem / 16 * 42);
$fs-xxxl: calc(1rem / 16 * 68);

$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-bold: 700;

$fontsizes: (
    fs-xxxl: $fs-xxxl,
    fs-xxl: $fs-xxl,
    fs-xl: $fs-xl,
    fs-l: $fs-l,
    fs-m: $fs-m,
    fs-s: $fs-s
);

$fontweights: (
    light: $fw-light,
    regular: $fw-regular,
    medium: $fw-medium,
    bold: $fw-bold
);