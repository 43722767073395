@import "../../../styles/layout.scss";
@import "../../../styles/fonts.scss";
@import "../../../styles/colors.scss";
@import "../../../styles/mixins.scss";

.Overview {
    &__wrapper {
        @extend .layout-page;
        padding-bottom: 4rem;

        &--black {
            background-color: $color__black;
            max-width: 100%;
        }
    }

    &__center {
        text-align: center;
    }

    &__right--desktop {
        text-align: right;
    }

    &__dot:not(:last-child) {
        position: relative;
        margin-right: 1rem;

        &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: calc(100% + 0.5rem - 1.5px);
            display: inline-block;
            height: 3px;
            width: 3px;
            border-radius: 100%;
            background-color: $color__gray-1;
            transform: translateY(-50%);
        }
    }

    &__text {
        &--small {
            font-size: $fs-s;
        }
    }

    &__title {
        margin-top: 0;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.05rem;

        &--small {
            @extend .Overview__title;
            font-size: $fs-m;
            text-align: left;
            text-transform: uppercase;
        }

        &--mobile {
            display: none;
        }
    }

    &__barList {
        margin-top: 1rem;
        margin-bottom: 3rem;
        margin-right: 1rem;
    }

    &__heatmap {
        &Label {
            display: flex;
            justify-content: center;
            font-weight: $fw-bold;
            font-size: $fs-l;
        }
        &Icon {
            height: 1.2rem;

            svg {
                height: 100%;
                display: inline-block;
            }
        }
    }

    &__top {
        display: flex;
        justify-content: space-between;
    }

    &__description {
        margin: 0;
        font-size: $fs-s;
    }
}

@media (max-width: 770px) {
    .Overview {
        &__wrapper {
            padding: 0 1rem 2rem;
        }

        &--desktop {
            display: none;
        }

        &__title {
            @each $name, $color in $colors {
                &--#{$name} {
                    position: relative;
                    font-size: $fs-l;
                    text-align: left;
                    margin-bottom: 0rem;
                    margin-top: 1rem;
                    padding-left: 1.5rem;

                    &:before {
                        content: "";
                        position: absolute;
                        height: 16px;
                        width: 16px;
                        display: inline-block;
                        border-radius: 100%;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        background-color: $color;
                    }
                }
            }

            &--mobile {
                display: inline-block;
            }

            &--secondary {
                margin-bottom: 0;
            }

            &Description {
                margin-bottom: 2rem;
                margin-top: 0;
            }
        }

        &__barList {
            margin-top: 1rem;
        }

        &__text--small {
            display: flex;
            flex-direction: column;
            margin: 0;

            span:after {
                display: none !important;
            }
        }

        &__speedometer--mobile {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
        }

        &__center {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &__right--desktop {
            text-align: left;
        }

        &__paddingBottom {
            padding-bottom: 4rem;
        }

        &__noPaddingTop--mobile {
            padding-top: 0;
        }
    }
}
