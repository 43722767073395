@import "../../../styles/fonts.scss";
@import "../../../styles/colors.scss";

.UsersList {
    position: relative;

    &__top {
        display: inline-block;
        position: sticky;
        top: 0;

        &--left,
        &--right {
            @extend .UsersList__top;
        }

        &--right {
            padding-left: 1rem;
        }

        &--left {
            padding-right: 1rem;
            border-right: 1px solid $color__gray-3;
        }

        &--buttons {
            display: flex;
            gap: 1rem;
        }
    }

    &__loader {
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
    }

    &__list {
        padding-right: 0.2rem;
        max-height: 70vh;
        overflow: auto;
    }

    &__actions {
        display: flex;
        justify-content: flex-end;
    }

    &__flex {
        display: flex;
        gap: 0.5rem;
    }

    &__text {
        &--medium {
            font-weight: $fw-medium;
        }
        &--right {
            text-align: right;
        }
    }

    &__saved {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 0.5rem;
        color: $color__gray-2;

        svg {
            fill: $color__gray-2;
        }
    }
}

@media (max-width: 1180px) {
    .UsersList {
        &__top {
            &--right {
                padding-left: 0;
                margin-top: 1rem;
            }

            &--left {
                border-right: none;
            }
        }
    }
}
