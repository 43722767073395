@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";

.Roles {
    &__mobile {
        display: none;
    }

    &__box--dashed {
        border-right: 1px dashed $color__gray-3;
        height: 100%;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
    }

    &__title {
        width: min-content;
        white-space: break-spaces;
    }

    &__number {
        margin: 0;
        font-size: $fs-xl;
        display: flex;
        align-items: center;
        gap: 1rem;
    }
}

@media (max-width: 770px) {
    .Roles {
        &__desktop {
            display: none;
        }
        &__mobile {
            display: inline-block;
            width: 100%;
        }
        &--mobile {
            &Bottom {
                border: none;
                border-bottom: 1px dashed $color__gray-3;
                display: flex;
                justify-content: space-between;
            }
            &Colon:after {
                content: ":";
            }
        }

        &__title {
            width: 100%;
            font-weight: $fw-bold;
            font-size: $fs-l;
            display: flex;

            svg {
                display: inline-block;
                height: 1.5rem;
            }
        }

        &__center {
            text-align: center;
            justify-content: center;
        }
    }
}
