@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.Popup {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 10000;
    top: 0;
    left: 0;
    background-color: rgba($color__black, 0.7);

    &__wrapper {
        display: flex;
        align-items: center;
        margin: auto;
        max-width: 80rem;
        height: 100%;
        padding: 1rem;
    }

    @each $name, $color in $colors {
        &--#{$name} {
            @extend .Popup;
            $wrapper: ".Popup";

            #{$wrapper}__field,
            #{$wrapper}__field h2 {
                color: invert($color);
            }

            #{$wrapper}__field h2 {
                margin: 0;
            }
        }
    }

    &__fields {
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        max-height: 60vh;
    }

    &__field {
        &--overflow {
            overflow: auto;
        }

        label {
            display: inline-block;
            margin-bottom: 0.5rem;
        }
    }

    &__actions {
        display: flex;
        justify-content: center;
        gap: 1rem;

        &--message {
            position: absolute;
            right: calc(100% + 1rem);
            top: 50%;
            margin: 0;
            transform: translateY(-50%);
        }
    }

    &__button {
        display: inline-block;
        position: relative;

        &Inner {
            padding: 0.5rem 1rem;
        }
    }
}
