@import "../../styles/colors.scss";
@import "../../styles/mixins.scss";

.Slider {
    &__wrapper {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        position: relative;
        margin: 0.5rem 0;
    }

    &__line {
        cursor: grab;
        position: absolute;
        top: -0.45rem;
        height: calc(100% + 0.9rem);
        width: 9px;
        transform: translate(0, 0);
        background-color: $color__gray-1;
        border: 2px solid $color__white;
        border-radius: 10px;
        filter: drop-shadow(0px 2px 7px rgba($color__black, 0.25));

        &:active {
            cursor: grabbing;
        }

        &[draggable=false]  {
            cursor: default;
        }

        @each $name, $color in $colors {
            &--#{$name} {
                @extend .Slider__line;
                background-color: $color;
                border-color: $color__gray-1;
            }
        }

        &--dragging {
            @extend .Slider__line;
            opacity: 0;
        }
    }
}
