@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";

.CheckboxField {
    position: relative;
    width: 1rem;
    height: 1rem;
    cursor: pointer;

    &__label {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 1rem;
        height: 1rem;
        pointer-events: none;

        &:before {
            content: "";
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            background-color: $color__white;
            border: 1px solid $color__gray-2;
            border-radius: 2px;
        }
    }

    &__checkbox {
        position: absolute;
        padding: 0;
        margin: 0;
        width: 1rem;
        height: 1rem;
        top: 0;
        left: 0;
        cursor: pointer;
    }

    &__checkbox:checked + &__label {
        &:before {
            content: "";
            background-color: $color__green;
            border-color: $color__green;
        }

        &:after {
            content: url("../../assets/icons/checkmark.svg");
            position: absolute;
            left: 54%;
            top: 49%;
            transform: translate(-50%, -50%);
        }
    }
}
