@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.BarListYlines {
    position: absolute;
    display: flex;
    margin-left: -1px;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;

    &--line {
        position: relative;
        width: 100%;
        height: calc(100% + 1rem);
        border-right: 1px dashed rgba($color__gray-2, 0.6);

        &:first-child {
            border-left: 1px solid $color__gray-3;
        }

        span {
            position: absolute;
            left: calc(100% - 9px);
            top: calc(100% + 0.3rem);
            font-size: $fs-s;
            color: $color__gray-2;
        }

        &--0 {
            left: unset !important;
            right: calc(100% - 4px);
        }
    }
    &--100 span {
        left: calc(100% - 14px);
    }

    &--hide {
        height: 100%;

        span {
            display: none;
        }
    }
}
