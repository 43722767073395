@import "../../styles/colors.scss";

.Page {
    min-height: 100vh;
    position: relative;

    @each $name, $color in $colors {
        &--#{$name} {
            @extend .Page;
            background-color: $color;
            h1,
            h2,
            h3,
            h4,
            p,
            span,
            a {
                color: $color__black;
            }
        }

        &--white {
            color: $color__black;

            h1,
            h2,
            h3,
            h4,
            p,
            span,
            a {
                color: $color__black;
            }
        }
    }
}
