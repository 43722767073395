@import "../../styles/colors.scss";

.Alert {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($color__black, 0.5);
    z-index: 100;
    pointer-events: none;

    &__box {
        z-index: 100;
        width: 40rem;
        max-width: 80vw;
        background-color: $color__gray-2;
        padding: 1rem;
        border-radius: 6px;
        box-shadow: 1px 2px 7px 2px rgba($color__gray-2, 0.4);
    }
}
