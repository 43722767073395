@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

$switchWidth: 2.4rem;
$dotDim: 1.4rem;

.Toggle {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: $fs-s;

    &__switch {
        position: relative;
        display: inline-block;
        width: $switchWidth;
        height: 1rem;

        input {
            opacity: 0;
            width: 0;
            height: 0;
        }

        input:checked + .Toggle__slider {
            background-color: rgba($color__green, 0.4);

            &:before {
                background-color: $color__green;
                transform: translate(calc($switchWidth - $dotDim), -50%);
            }
        }
    }

    &__slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba($color__gray-3, 0.4);
        border-radius: 1rem;

        &:before {
            position: absolute;
            content: "";
            height: $dotDim;
            width: $dotDim;
            left: 0;
            top: 50%;
            transform-origin: left;
            transform: translateY(-50%);
            border-radius: 100%;
            background-color: $color__gray-3;
            transition: 0.2s;
        }
    }
}
