@import "../../../styles/fonts.scss";
@import "../../../styles/colors.scss";

.CreateSurveySegmentation {
    &__groups {
        display: flex;
        flex-direction: column;

        &--row {
            display: flex;
            align-items: center;
            height: 55px;
        }

        &--column {
            width: 15rem;
            display: flex;
            align-items: center;
        }
    }

    &__units {
        display: flex;
        gap: 7rem;

        &--column {
            width: 100%;
            max-width: 20rem;
        }
    }

    &__field {
        width: 100%;
        display: flex;
        align-items: flex-start;
        gap: 1rem;
        margin: 0.5rem 0;

        &--actions {
            display: flex;
            align-items: center;
            height: 21px;
        }

        &--label {
            margin: 0;
            width: 100%;
            max-width: 7rem;
            display: flex;
            flex-direction: column;
        }

        &--input {
            width: 100%;
        }
    }

    &__text--small {
        font-size: $fs-s;
        color: $color__gray-2 !important;
    }
}
