@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.Tooltip {
    position: relative;
    padding: 0.2rem 1.5rem;
    background-color: $color__white;
    color: $color__gray-1;
    border-radius: 4px;
    display: inline-block;
    font-weight: $fw-regular;
    filter: drop-shadow(rgba($color__gray-1, 0.3) 2px 2px 3px);

    &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        border: 5px solid;
        border-color: $color__white transparent transparent transparent;
    }

    &--hidden {
        display: none;
    }
}
