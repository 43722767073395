@import "../../styles/colors.scss";

.Accordion {
    position: relative;
    border-bottom: 1px solid $color__gray-2;

    &--disabled {
        @extend .Accordion;
        pointer-events: none;
        color: $color__gray-3;

        .Accordion__title:before, .Accordion__title:after {
            background-color: $color__gray-3;
        }
    }

    &--open,
    &--close {
        @extend .Accordion;
    }

    &__title {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding-bottom: 1rem;

        &:before, &:after {
            content: "";
            position: relative;
            display: block;
            height: 2px;
            width: 1rem;
            background-color: $color__black;
            margin-right: 0.7rem;
        }

        &:after {
            position: absolute;
            transform: rotate(0);
            left: 0;
            transition: transform ease-in 0.2s;
        }
    }

    &--closed {
        .Accordion__title:after {
            transform: rotate(-90deg);
        }
    }

    &__child {
        padding-left: 2rem;
    }
}
