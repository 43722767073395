@import "../../../styles/colors.scss";
@import "../../../styles/fonts.scss";

.CreateSurveySummary {
    &__title {
        color: $color__gray-2 !important;
        margin: 0;
        margin-bottom: 0.5rem;
    }

    &__text {
        margin: 0;
        font-weight: $fw-medium;
    }

    &__spaceBetween {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0 0.5rem 0 0.3rem;
    }

    &__user {
        margin-bottom: 1rem;
        max-height: 30vh;
        overflow: auto;

        &Column {
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0;
        }
    }
}
