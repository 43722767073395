@import "../../../styles/colors.scss";

.Description {
    z-index: 99;
    height: 1.1rem;
    width: 1.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $color__black;
    border-radius: 100%;
    position: absolute;
    right: 1rem;
    top: 1rem;
    cursor: pointer;

    &__popup {
        cursor: default;
        bottom: calc(100% + 0.5rem);
        right: calc(100% - 1rem);
        position: absolute;
        background-color: $color__white;
        padding: 1rem;
        min-width: 20rem;
        border-radius: 0.5rem;
        box-shadow: 6px 9px 7px 0px rgba($color__gray-3, 0.2);

        p {
            margin: 0;
        }
    }
}
