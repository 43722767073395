// GRAY SCALE
$color__black: #0f0f0f;
$color__white: #fff;
$color__gray-0: #222222;
$color__gray-1: #333333;
$color__gray-2: #989898;
$color__gray-3: #d0d0d0;
$color__gray-4: #ededed;
$color__gray-5: #f5f5f5;

$color__beige: #D5CFBE;

// COLORS
$color__green: #31b468;
$color__yellow: #ffd166;
$color__blue: #118ab2;
$color__pink: #ef476f;
$color__turquoise: #50c9ce;
$color__purple: #9883e5;
$color__blue-light: #72a1e5;
$color__green-light: #19c587;
$color__red: #f75555;
$color__orange: #ff8b66;

$color__primary: $color__green;
$color__ok: $color__green-light;
$color__warning: $color__orange;
$color__danger: $color__red;

// MATCHING GRADIENT
$color__grad-0: #fd7f6f;
$color__grad-1: #7eb0d5;
$color__grad-2: #b2e061;
$color__grad-3: #bd7ebe;
$color__grad-4: #ffb55a;
$color__grad-5: #ffee65;
$color__grad-6: #beb9db;
$color__grad-7: #fdcce5;
$color__grad-8: #8bd3c7;


// MAPPING
$colors: (
    "black": $color__black,
    "white": $color__white,
    "gray-0": $color__gray-0,
    "gray-1": $color__gray-1,
    "gray-2": $color__gray-2,
    "gray-3": $color__gray-3,
    "gray-4": $color__gray-4,
    "gray-5": $color__gray-5,
    "green": $color__green,
    "yellow": $color__yellow,
    "blue": $color__blue,
    "pink": $color__pink,
    "turquoise": $color__turquoise,
    "purple": $color__purple,
    "blue-light": $color__blue-light,
    "green-light": $color__green-light,
    "red": $color__red,
    "orange": $color__orange,
    "transparent": transparent,
    "primary": $color__primary,
    "ok": $color__ok,
    "warning": $color__warning,
    "danger": $color__danger,
    "grad-0": $color__grad-0,
    "grad-1": $color__grad-1,
    "grad-2": $color__grad-2,
    "grad-3": $color__grad-3,
    "grad-4": $color__grad-4,
    "grad-5": $color__grad-5,
    "grad-6": $color__grad-6,
    "grad-7": $color__grad-7,
    "grad-8": $color__grad-8
);
