@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.Speedometer {
    position: relative;
    display: inline-block;
    width: 100%;

    &__pieChart {
        position: relative;
        display: inline-block;

        > div:not(.Speedometer__pinWrapper):not(.Speedometer__title) {
            transform: rotate(225deg);
        }
    }

    &__rest {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }

    &__goal {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 100%;

        &--line {
            position: relative;
            display: inline-block;
            height: 30px;
            margin-top: 2px;
            width: 3px;
            background-color: $color__gray-2;
            border: 2px solid $color__gray-5;
            border-radius: 5px;
            box-sizing: content-box;
        }

        &--number {
            position: absolute;
            bottom: calc(100% + 5px);
            left: 50%;
            font-size: $fs-s;
            font-weight: $fw-bold;
            color: $color__gray-2;
            overflow: overlay;
        }
    }

    &__pin {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 100%;

        @each $name, $fontsize in $fontsizes {
            &--#{$name} {
                > div {
                    font-weight: $fw-bold;
                    font-size: $fontsize;
                }
            }
        }

        &Wrapper {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 0;
            height: 350px;
            width: 100%;

            &--background {
                > div {
                    background-color: rgba($color__black, 0.3);
                    border: 4px solid rgba($color__white, 0.5);
                    border-radius: 100%;
                    font-weight: $fw-medium;
                }
            }
        }

        > div {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    svg g g:not(:first-child) {
        opacity: 0.8;
    }

    &__title {
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: 0;
        width: 100%;
        text-transform: uppercase;
        font-weight: bold;
        font-size: $fs-m;
        color: $color__white;
    }
}
