@import "../../styles/colors.scss";
@import "../../styles/mixins.scss";

.Sidebar {
    position: fixed;
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    overflow-y: auto;
    left: 0;
    top: 0;
    z-index: 999;
    pointer-events: none;
    color: $color__black;

    h2,
    p {
        color: $color__black;
    }

    & * {
        pointer-events: all;
    }

    &__background {
        background-color: rgba($color__black, 0.5);
        height: 100%;
        width: 100%;
        display: none;

        &--open {
            display: block;
            animation: open-sidebar-background 1s;
            animation-fill-mode: forwards;
        }

        &--close {
            display: block;
            pointer-events: none;
            animation: close-sidebar-background 1s;
            animation-fill-mode: forwards;
        }
    }

    &__wrapper {
        position: absolute;
        height: 100%;
        width: 100%;
        max-width: 380px;
        top: 0;
        left: 100%;
        background-color: $color__white;
        overscroll-behavior: contain;
        overflow-x: hidden;
        overflow-y: auto;

        &--open {
            display: block;
            animation: open-sidebar 1s;
            animation-fill-mode: forwards;
        }

        &--close {
            display: block;
            animation: close-sidebar 1s;
            animation-fill-mode: forwards;
        }
    }

    &__container {
        padding: 0 1rem 1rem;
    }

    &__header {
        background-color: $color__white;
        position: sticky;
        top: 0;
        padding: 1rem;
        z-index: 10;

        & > div {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            padding: 0 0 0 0;
            margin-right: -1rem;

            h2 {
                margin: 0;
            }
        }
    }

    &__paragraph {
        margin: 0;
    }

    &__ylines {
        position: relative;
        height: 100%;
        top: 0;
        left: 0;
    }

    &__progress {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}

@keyframes open-sidebar-background {
    from {
        opacity: 0;
        visibility: hidden;
    }
    to {
        opacity: 1;
        visibility: visible;
    }
}

@keyframes close-sidebar-background {
    from {
        opacity: 1;
        visibility: visible;
    }
    to {
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes open-sidebar {
    from {
        transform: translate(0, 0);
    }
    to {
        transform: translate(-100%, 0);
    }
}

@keyframes close-sidebar {
    from {
        transform: translate(-100%, 0);
    }
    to {
        transform: translate(0, 0);
    }
}
