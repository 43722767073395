@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.Button {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: $color__green;
    border: 2px solid $color__green;
    border-radius: 6px;
    font-weight: $fw-medium;
    font-size: $fs-m;
    color: $color__white;

    &--secondary {
        @extend .Button;
        background-color: transparent;
        color: $color__white;
    }

    &:hover:not(:disabled) {
        background-color: darken($color__green, 10);
        border-color: darken($color__green, 10);
        color: $color__white;
        cursor: pointer;
    }

    &:disabled:not(:has(svg)) {
        background-color: $color__gray-3;
        border-color: $color__gray-3;
        cursor: unset;
    }

    @each $name, $color in $colors {
        &--background#{$name} {
            @extend .Button;
            background-color: $color;

            &:hover:not(:disabled) {
                background-color: darken($color, 10);
            }

            @if $name == "transparent" {
                &:hover:not(:has(svg)) {
                    background-color: rgba($color__black, 0.2);
                }
            }
        }

        &--border#{$name} {
            @extend .Button;
            border-color: $color;

            &:hover:not(:disabled) {
                border-color: darken($color, 10);
            }
        }

        &--color#{$name} {
            @extend .Button;
            color: $color;

            &:hover:not(:disabled) {
                color: darken($color, 10);
            }


            @if $name == "black" {
                &:hover:not(:disabled) {
                    color: $color__gray-2;
                }
            }
        }
    }
}
