@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.InputField {
    position: relative;
    width: 100%;

    &__input {
        padding: 1.5rem 0.5rem 0.5rem;
        font-family: $ff;
        background-color: darken($color__gray-1, 5);
        border: none;
        border: 1px solid $color__gray-2;
        border-radius: 4px;
        width: 100%;
        color: $color__white;
        font-size: $fs-m;

        &--noPlaceholder {
            @extend .InputField__input;
            padding: 0.5rem 0.5rem 0.5rem;
        }

        &:focus-visible {
            outline: none;
            border: 1px solid $color__green;
        }

        @each $name, $color in $colors {
            &--#{$name} {
                @extend .InputField__input;
                background-color: $color;
                border-color: darken($color, 15);
                color: invert($color);
            }
        }

        @each $name, $fontsize in $fontsizes {
            &--#{$name} {
                font-size: $fontsize;
            }
        }

        @each $name, $fontweight in $fontweights {
            &--#{$name} {
                font-weight: $fontweight;
            }
        }

        &:read-only {
            border-color: transparent;
        }

        &[type="number"] {
            -moz-appearance: textfield;
        }

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    &__placeholder {
        position: absolute;
        pointer-events: none;
        font-size: $fs-s;
        margin: 0;
        left: 0.5rem;
        top: 0.3rem;
        color: $color__gray-2 !important;
        z-index: 10;
    }
}
