.Menubar {
    padding-top: 1rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
}

@media (max-width: 770px) {
    .Menubar {
        justify-content: space-between;
    }
}