@import "../../styles/fonts.scss";

.Descriptions {
    margin: 1rem 0 0;

    p {
        font-size: $fs-s;
        margin: 0.1rem;
    }
}
