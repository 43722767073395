@import "../../../styles/colors.scss";
.UsersListFilters {
    display: inline-block;

    &__container {
        display: flex;
        gap: 1rem;
        z-index: 90;
        position: relative;
    }
}
