@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.Hero {
    position: relative;
    max-width: 1400px;
    padding: 0 2rem;

    &--hide {
        visibility: hidden;
    }

    &__wrapper {
        position: relative;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        gap: 1rem;
        padding: 2rem 2rem 3rem;
        max-width: 1400px;
        margin: auto;
    }

    &__title {
        font-size: $fs-xxl;

        &--hidden {
            @extend .Hero__title;
            visibility: hidden;
        }
    }

    &__data {
        display: flex;
        gap: 1rem;
        align-items: center;

        h2 {
            font-size: $fs-xxl;
            margin: 0;
        }

        @each $name, $fontweight in $fontweights {
            &--#{$name} {
                @extend .Hero__data;
                h2 {
                    font-weight: $fontweight;
                }
            }
        }
    }

    &__details {
        &--detail {
            padding-right: 0.5rem;
            border-right: 1px dashed $color__gray-3;

            &--noborder {
                border: none;
            }
        }
    }

    &__header {
        font-weight: $fw-bold;

        @each $name, $fontweight in $fontweights {
            &--#{$name} {
                @extend .Hero__header;
                font-weight: $fontweight;
            }
        }
    }

    &__content {
        grid-column: 7 span;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__action {
        display: flex;
        justify-content: flex-end;
        margin-top: 2rem;
    }

    &__button {
        padding: 0 1rem;
        font-size: $fs-s;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0.5rem;

        p {
            white-space: nowrap;
        }

        svg,
        p {
            flex: 1;
        }

        svg {
            width: 1.5rem;
            height: 1.5rem;
        }
    }

    &__line {
        height: 1px;
        width: 100%;
        background-color: $color__gray-1;
    }
}

@media (max-width: 672px) {
    .Hero {
        padding: 0 1rem;

        &__background {
            height: 23rem;
        }

        &__wrapper {
            padding: 2rem 1rem 3rem;
            row-gap: 5rem;
            overflow: hidden;
        }

        &__title {
            font-size: $fs-xl;
            word-break: break-all;
        }
    }
}
