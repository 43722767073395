@import "../../styles/colors.scss";

@keyframes loader {
    0% {
        background-color: $color__yellow;
        transform: scale(1, 0);
    }
    40% {
        background-color: $color__blue;
        transform: scale(1, 0.5);
    }
    80% {
        background-color: $color__purple;
        transform: scale(1, 0.5);
    }
    100% {
        background-color: $color__yellow;
        transform: scale(1, 0.1);
    }
}

.Loader {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    
    &__bar {
        display: inline-block;
        height: 100px;
        width: 12px;
        margin: 0 3px;
        background-color: $color__yellow;
        animation-name: loader;
        animation-duration: 4s;
        animation-iteration-count: infinite;
        transform: scale(0, 0);
        transform-origin: bottom;

        &--1 {
            @extend .Loader__bar;
        }
        &--2 {
            @extend .Loader__bar;
            animation-delay: 1s;
        }
        &--3 {
            @extend .Loader__bar;
            animation-delay: 2s;
        }
    }
}
