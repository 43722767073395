@use "sass:math";
@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

$labels: "100", "80", "60", "40", "20", "0";

.BarChart {
    margin: 2rem 0 4rem;
    flex-direction: column;
    display: flex;
    justify-content: flex-end;

    &__chart {
        position: relative;
    }

    &__wrapper {
        display: flex;
        height: 200px;
        max-width: 80%;
        margin: auto;
    }

    &__column {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: column;

        &Wrapper {
            height: 100%;
            width: 100%;
            display: flex;
            gap: 2px;
            padding: 0 10px;
            align-items: flex-end;
            justify-content: center;
        }
    }

    &__tooltip {
        opacity: 0;
        white-space: nowrap;

        &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: calc(100% + 1px);
            display: block;
            height: 1px;
            background-color: green;
        }

        &:after {
            content: "";
            position: absolute;
            top: 50%;
            display: block;
            height: 1px;
            transform-origin: left;
            transform: rotate(-45deg);
            background-color: red;
        }

        @each $name, $color in $colors {
            &--#{$name} {
                @extend .BarChart__tooltip;
                &:before,
                &:after {
                    background-color: $color;
                }
            }
        }

        @for $i from 0 through 20 {
            $length: 40;
            $w: $i * 5 + 5;
            $a: $length - $w;
            $h: math.sqrt(math.pow($a, 2) + math.pow($a, 2));
            &--#{$i} {
                @extend .BarChart__tooltip;
                & > div {
                    left: calc(100% + #{$length}px + 2px);
                    top: 50%;
                    transform: translateY(calc(#{-$a}px - 0.8rem));
                    font-size: $fs-s;
                    background-color: rgba($color__white, 0.5);
                    border-radius: 3px;
                    position: absolute;
                }
                &:before {
                    // horizontal, small line
                    width: calc(#{$w - 1}px);
                }
                &:after {
                    // diagonal, longer line
                    left: calc(100% + #{$w}px);
                    width: calc(#{$h}px);
                }
            }
        }
    }

    &__bar {
        z-index: 1;
        border-radius: 4px 4px 0 0;
        position: relative;

        &Score {
            font-weight: bold;
            position: absolute;
            bottom: 100%;
            width: 100%;
            text-align: center;
        }

        &:hover {
            z-index: 10;

            .BarChart__tooltip {
                opacity: 1;
            }
        }
    }

    &__stack:hover {
        font-weight: bold;

        .BarChart__tooltip:before,
        .BarChart__tooltip:after {
            height: 3px;
        }
    }

    &__label {
        font-size: $fs-s;
        position: absolute;
        top: 100%;
        text-align: center;
        width: 100%;

        &Wrapper {
            width: 100%;
            position: relative;
        }
    }
}

@media (max-width: 770px) {
    .BarChart {
        margin: 0;
    }
}
