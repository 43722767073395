@import "../../styles/colors.scss";

.PopupSmall {
    position: relative;

    &__close {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
    }

    &__box {
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        min-width: 500px;
        position: absolute;
        padding: 1rem 2rem 2rem;
        background-color: $color__white;
        bottom: 100%;
        right: 0;
        box-shadow: 2px 2px 6px 3px rgba($color__gray-3, 0.54);
        border-radius: 20px;

        &--open {
            display: flex;
        }

        &--closed {
            display: none;
        }
    }

    &__title {
        text-transform: uppercase;
    }
}
