@import "../../../styles/colors.scss";

.HomeLink {
    display: inline-block;

    a {
        display: flex;
        align-items: flex-end;
        gap: 0.2rem;
    }

    &:hover {
        a {
            color: $color__green;
        }

        svg {
            fill: $color__green;
        }
    }
}
