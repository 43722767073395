@import "../../styles/colors.scss";

.ButtonPlainText {
    cursor: pointer;
    display: inline-block;

    p {
        color: $color__green;
        margin: 0.5rem 0;
    }
}
