@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";

.UserChip {
    padding-right: 1rem;
    cursor: default;
    overflow: hidden;

    &--clickable:hover {
        cursor: pointer;

        .UserChip__edit {
            opacity: 1;

            svg {
                fill: $color__green;
            }
        }
    }

    &__top {
        display: flex;
        justify-content: space-between;
        gap: 0.5rem;

        p {
            color: $color__green;
        }
    }

    &__text {
        margin: 0;
        white-space: nowrap;
        font-weight: $fw-medium;
        overflow: hidden;
        text-overflow: ellipsis;

        &--small {
            @extend .UserChip__text;
            font-size: $fs-s;
            font-weight: $fw-regular;
            color: $color__black;
        }
    }

    &__edit {
        opacity: 0;

        svg {
            height: 1rem;
            width: 1rem;
        }
    }
}
