.SettingsSurveys {
    &__add {
        display: flex;
        justify-content: flex-end;
    }

    &__loader {
        position: absolute;
        width: 100%;
        top: 5rem;
    }
}
