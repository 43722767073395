@import "../../../styles/colors.scss";
@import "../../../styles/fonts.scss";

$labels: "100", "80", "60", "40", "20", "0";

.Ylines {
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &__yLabels {
        position: absolute;
        left: 0px;
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 6%;
        align-items: flex-end;
    }

    &__yLabel {
        flex: 1;
        display: flex;
        width: 26px;
        align-items: flex-end;

        span {
            transform: rotate(-90deg);
            transform-origin: left top;
        }
    }

    &__line {
        position: relative;
        border-top: 1px dashed rgba($color__gray-2, 0.6);
        width: 80%;

        &:last-child {
            border-top-style: solid;
            border-top-color: $color__gray-2;
            z-index: 20;
            width: 90%;

            &:after {
                display: none;
            }
        }

        span {
            position: absolute;
            right: calc(100% + 0.3rem);
            color: $color__gray-2;
            top: 50%;
            transform: translateY(-50%);
            font-size: $fs-s;
        }

        &:last-child span {
            opacity: 0;
        }

        &--0 {
            @extend .Ylines__line;
            border-top-width: 2px;
            border-top-style: solid;
            span {
                font-weight: bold;
            }
        }
    }
}

@media (max-width: 770px) {
    .Ylines {
        &:before,
        &:nth-child(2n):after {
            content: "";
        }
        &:nth-child(2n + 1):after {
            top: -1.5rem;
            right: unset;
            right: 0;
        }
    }
}
