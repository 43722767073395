.AddUsersPopup {
    &__header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__units {
        display: flex;
        gap: 1rem;
    }

    &__file {
        visibility: hidden;
    }
}
