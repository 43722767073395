@import "./styles/colors.scss";

.App {
    background-color: $color__gray-5;

    &__loader {
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: center;
    }
}
