@import "../../../styles/layout.scss";

.Settings {
    &__wrapper {
        @extend .layout-page;
        padding-top: 3rem;
        padding-bottom: 4rem;
    }

    &--hidden {
        display: none;
    }

    &__input {
        display: flex;
        gap: 0.5rem;
    }
}
