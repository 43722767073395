@import "../../../styles/colors.scss";
@import "../../../styles/fonts.scss";

.CreateSurveyInfoForm {
    &__title {
        margin: 0;
        margin-bottom: 0.5rem;
    }

    &__description {
        margin: 0.2rem 0 0.5rem;
        color: $color__gray-2 !important;
        font-size: $fs-s;
    }
}
