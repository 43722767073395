@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";

.Alignment {
    display: flex;
    gap: 0.5rem;

    &__text {
        line-height: 0;
        font-size: $fs-s;
        display: flex;
        align-items: center;
        gap: 0.5rem;

        span {
            white-space: nowrap;
        }
    }

    &__icon {
        margin-right: 0.5rem;
        height: 14px;
        width: 14px;
    }

    @each $name, $color in $colors {
        &__legend--#{$name} {
            background-color: $color;
        }
        &__icon--#{$name} {
            @extend .Alignment__icon;
            fill: $color;
        }
    }
}
