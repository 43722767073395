.GridColumn {
    padding: 0 1rem;
    position: relative;

    &--nopadding {
        padding: 0;
    }

    &--mobile {
        display: none;
    }

    &--center {
        height: 100%;
        display: flex;
        align-items: center;
    }

    &--right {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    @for $i from 1 through 12 {
        &--#{$i} {
            grid-column: span $i;
        }
    }

    @for $i from 1 through 12 {
        &--desktopOrder#{$i} {
            order: $i;
        }
    }
}

@media (max-width: 770px) {
    .GridColumn {
        padding: 0;

        &--desktop {
            display: none;
        }

        &--mobile {
            display: block;
        }

        @for $i from 1 through 12 {
            &--#{$i} {
                grid-column: span 12;
            }
        }
        @for $i from 1 through 12 {
            &--mobile#{$i} {
                grid-column: span $i;
            }
        }

        @for $i from 1 through 12 {
            &--desktopOrder#{$i} {
                order: unset;
            }
        }
    }
}
