@import "../../../styles/layout.scss";
@import "../../../styles/colors.scss";

.Admin {
    &__wrapper {
        @extend .layout-page;
        padding-top: 1rem;
    }

    &__barchart {
        padding: 0 2rem;
    }

    &__right {
        text-align: right;
        margin-top: 1rem;
    }

    &__button {
        display: inline-block;
        
        &Inner {
            padding: 0.3rem 0.8rem;
        }
    }
}
