@import "./styles/colors.scss";
@import "./styles/fonts.scss";

body {
  font-family: $ff;
  font-weight: $fw-regular;
  font-size: $fs-m;
  margin: 0;
  background-color: $color__gray-5;
  color: $color__gray-1;

  &.disabled-scroll {
    overflow-y: hidden;
    overscroll-behavior-y: none;
  }

  * {
    $thumb: $color__green;
    $track: $color__gray-4;
    $background: $color__white;
    scrollbar-color: $thumb $track;
    scrollbar-width: thin !important;

    &::-webkit-scrollbar {
      background-color: $background;
      width: 0.4rem;
      opacity: 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $thumb;
      border-radius: 4px;
      display: none;
    }

    &::-webkit-scrollbar-track {
      background-color: $track;
      border-radius: 4px;
      display: none;
    }

    &:hover {
      &::-webkit-scrollbar {
        background-color: $background;
        width: 0.4rem;
        height: 0.4rem;
      }
  
      &::-webkit-scrollbar-thumb {
        background-color: $thumb;
        border-radius: 4px;
        display: block;
      }
  
      &::-webkit-scrollbar-track {
        background-color: $track;
        border-radius: 4px;
        display: block;
      }
    }
  }
}

h1, h2, h3, h4 {
  font-weight: 700;
  text-transform: uppercase;
}

h1 {
  font-size: $fs-xl;
  letter-spacing: 1.2px;
}

a {
  color: $color__gray-1;
  text-decoration: none;
}

a:hover {
  color: $color__gray-3;
}

* {
  box-sizing: border-box;
}
