@import "../../../styles/layout.scss";
@import "../../../styles/colors.scss";
@import "../../../styles/fonts.scss";

.CreateSurvey {
    @extend .layout-padding;
    @extend .layout-page;
    color: $color__black;

    &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__text {
        &--small {
            margin: 0;
            font-size: $fs-s;
        }
    }

    &__align--right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 0.5rem;
        width: 100%;
    }

    &__step {
        &Container {
            min-height: 80vh;
            padding: 1rem 2rem 3rem;
            background-color: $color__white;
            border-radius: 6px;

            &--visible {
                display: block;
                visibility: visible;
            }
            &--hidden {
                display: none;
                visibility: hidden;
            }
        }

        &Wrapper {
            max-height: 60vh;
            overflow: auto;
        }

        &Explanation {
            position: absolute;
            bottom: 0.5rem;
            right: 0.5rem;
        }
    }

    &__subtitle {
        border-bottom: 1px solid $color__gray-3;
        padding-bottom: 1rem;
        margin-bottom: 1.5rem;
    }

    &__disabled {
        pointer-events: none;
        color: $color__gray-3 !important;
    }
}
