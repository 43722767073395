@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.Block {
    width: 100%;
    position: relative;

    &--fill {
        height: 100%;

        .Block__wrapper {
            height: 100%;
        }
    }

    @each $name, $color in $colors {
        &--#{$name} {
            @extend .Block;
            $wrapper: ".Block";

            & > #{$wrapper}__wrapper {
                background-color: $color;
            }

            #{$wrapper}__header {
                color: invert($color);
            }
        }
    }

    &__wrapper {
        background-color: $color__gray-1;
        border-radius: 6px;
        padding: 1.5rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.5rem;
    }

    &__action {
        display: flex;
        gap: 0.5rem;
        align-items: center;
    }

    &__menu {
        cursor: pointer;

        &:after {
            content: "\205E";
            font-size: $fs-xl;
        }
    }

    &__title {
        font-size: $fs-l;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-weight: bold;
        text-transform: uppercase;
        flex: 1 1 auto;
    }
}
