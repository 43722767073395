.SurveyPopup {
    &__table {
        &Header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1rem;

            & > div:first-child {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                width: 100%;
            }
        }
    }
}
