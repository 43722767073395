@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";

.TextArea {
    position: relative;
    border-radius: 4px;
    border: 1px solid $color__gray-3;

    &:has(:focus-visible){
        border: 1px solid $color__green;
    }

    &:has(&__area--disabled){
        .TextArea__actions {
            display: none;
        }
    }

    &__container {
        position: relative;
    }

    &__area {
        width: 100%;
        min-height: 7rem;
        padding: 1.5rem 0.5rem 0.5rem;
        font-family: $ff;
        font-size: $fs-m;
        color: $color__white;
        border-radius: 4px;
        resize: vertical;
        position: relative;

        &:focus-visible {
            outline: none;
        }

        &--disabled {
            opacity: 0.5;
        }

        @each $name, $color in $colors {
            &--#{$name} {
                @extend .TextArea__area;
                background-color: $color;
                border-color: darken($color, 15);
                color: invert($color);
            }
        }
    }

    &__placeholder {
        position: absolute;
        pointer-events: none;
        font-size: $fs-s;
        margin: 0;
        left: 0.5rem;
        top: 0.3rem;
        color: $color__gray-2 !important;
        z-index: 10;
    }

    &__actions {
        padding: 0.2rem;
        display: flex;
        gap: 0.2rem;
        margin: 0 0.5rem;
        border-bottom: 1px solid $color__gray-3;
    }
}
