@import "../../../styles/colors.scss";
@import "../../../styles/fonts.scss";

.ClusterLine {
    position: relative;
    height: 2px;
    background-color: $color__gray-1;
    border-left: 1px solid $color__gray-1;
    border-right: 1px solid $color__gray-1;
    margin: 4rem 2rem 4.4rem;

    &__dot {
        position: absolute;
        background-color: $color__beige;
        border-radius: 100%;
        top: 50%;
        transform: translateY(-50%);
    }

    &__tooltip {
        display: none;
        opacity: 0;
        position: absolute;
        justify-content: center;
        bottom: calc(100% + 2rem);
        z-index: 10;
    }

    &__dot:hover + &__tooltip {
        opacity: 1;
        display: flex;
    }

    &__label {
        position: absolute;
        left: 0;
        color: $color__gray-3;
        font-size: $fs-s;

        $h: 3rem;
        &:before {
            content: "";
            position: absolute;
            display: inline-block;
            top: 2px;
            left: 0;
            height: $h;
            width: 0;
            border: 1px dashed $color__gray-3;
            transform: translateX(-50%);
        }

        span {
            position: absolute;
            top: calc($h + 0.3rem);
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &__avg {
        position: absolute;
        transform: translateX(-50%);
        top: -3.2rem;
        font-size: $fs-s;

        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 100%;
            left: 50%;
            height: 4rem;
            width: 3px;
            border: 1px solid $color__white;
            background-color: $color__black;
            border-radius: 3px;
            transform: translateX(-50%);
        }
    }
}
