.AddUsersPopupManual {
    &__title {
        margin: 0;
    }

    &__fields {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        height: 100%;
    }

    &__action {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 1rem;
    }
}
