@import "../../../styles/colors.scss";
@import "../../../styles/fonts.scss";

.Preview {
    $browser: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    &__wrapper {
        display: flex;
        justify-content: center;
    }

    &__description {
        margin: 0;
        margin-top: 0.3rem;
        color: $color__gray-2 !important;
        font-size: $fs-s;
    }

    &__innerWrapper {
        position: relative;
        height: 624px;
        width: 1024px;
        color: $color__white;
        border-radius: 8px;
        overflow: hidden;
        transform-origin: center top;
        flex: 0 0 auto;
    }

    &__browser {
        position: relative;
        height: $browser;
        width: 100%;
        display: flex;
        align-items: center;
        background-color: $color__gray-4;
        z-index: 2;

        &--arrow {
            position: relative;
            display: flex;
            gap: 0.5rem;
            margin-left: 1rem;
            margin-right: 1.5rem;
            align-items: center;
            width: 3rem;

            &:before,
            &:after {
                position: absolute;
                display: block;
                height: 1rem;
                width: 1rem;
                color: $color__gray-2;
                font-size: $fs-l;
                font-weight: $fw-bold;
                top: 50%;
                transform: translateY(-80%);
            }
            &:before {
                content: "\2190";
                left: 0;
            }
            &:after {
                content: "\2192";
                right: 0;
            }
        }

        &--http {
            color: $color__gray-2;
            background-color: $color__white;
            width: 70%;
            height: 2rem;
            padding: 0.3rem 1rem;
            border-radius: 4px;
            pointer-events: none;

            &:after {
                content: "http://transitionview.anothertomorrow.com";
            }
        }
    }

    &__logo {
        z-index: 2;
        position: relative;
        width: auto;
        height: 2rem;
        display: inline-block;
        position: absolute;
        top: 4rem;
        left: 1rem;
    }

    &__background {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-image: url("../../../assets/img/typeform-background.jpeg");
        background-position: center top;
        background-size: cover;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.43;
            background-color: #000;
            pointer-events: none;
        }
    }

    &__content {
        position: relative;
        padding: 0 40px;
        z-index: 9;
        display: flex;
        height: calc(100% - $browser);
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__title {
        font-size: 24px;
        max-width: 720px;
    }

    &__text {
        margin-top: 8px;
        font-size: 20px;
        color: rgba($color__white, 0.7);
        text-align: center;
        max-width: 720px;

        p {
            color: rgba($color__white, 0.7);
        }
    }

    &__button {
        position: relative;
        margin-top: 32px;
        padding: 6px 14px;
        background-color: $color__white;
        border-radius: 4px;
        color: rgb(15, 15, 15);
        font-weight: $fw-bold;

        span {
            font-weight: $fw-regular;
            position: absolute;
            white-space: nowrap;
            left: 100%;
            margin-left: 12px;
            color: $color__white;
        }
    }
}
